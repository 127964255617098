// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.custom-card {
  /* Additional styling for Bootstrap card */
  background-color: #eaebec;
  /* Custom background color */
  border-radius: 10px;
  /* Custom border radius */
  border: 1px solid #eaebec;
  /* Custom border color */
  padding: 20px;
  /* Custom padding */
  margin: 20px;
  cursor: pointer;
}

.custom-card .card-title {
  /* Custom styling for card title */
  color: #007bff;
  /* Custom text color */
  font-size: 20px;
  /* Custom font size */
}

.custom-card .card-text {
  /* Custom styling for card text */
  color: #6c757d;
  /* Custom text color */
}

.custom-card:hover {
  /* Custom hover effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Custom box shadow */
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/style.scss"],"names":[],"mappings":"AAAA;;;;;;;;;;iGAAA;AAWA;EACI,0CAAA;EACA,yBAAA;EACA,4BAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;EACA,wBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAIA;EACI,kCAAA;EACA,cAAA;EACA,sBAAA;EACA,eAAA;EACA,qBAAA;AADJ;;AAIA;EACI,iCAAA;EACA,cAAA;EACA,sBAAA;AADJ;;AAIA;EACI,wBAAA;EACA,wCAAA;EACA,sBAAA;AADJ;;AAIA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AADJ;;AAMA;EACI,sBAAA;AAHJ","sourcesContent":["/*================================================================================\n\tItem Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template\n\tVersion: 2.0\n\tAuthor: PIXINVENT\n\tAuthor URL: http://www.themeforest.net/user/pixinvent\n================================================================================\n\nNOTE:\n------\nPLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.\nWE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */\n.custom-card {\n    /* Additional styling for Bootstrap card */\n    background-color: #eaebec;\n    /* Custom background color */\n    border-radius: 10px;\n    /* Custom border radius */\n    border: 1px solid #eaebec;\n    /* Custom border color */\n    padding: 20px;\n    /* Custom padding */\n    margin: 20px;\n    cursor: pointer;\n\n}\n\n\n.custom-card .card-title {\n    /* Custom styling for card title */\n    color: #007bff;\n    /* Custom text color */\n    font-size: 20px;\n    /* Custom font size */\n}\n\n.custom-card .card-text {\n    /* Custom styling for card text */\n    color: #6c757d;\n    /* Custom text color */\n}\n\n.custom-card:hover {\n    /* Custom hover effect */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    /* Custom box shadow */\n}\n\n.center {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n\n\n.card {\n    background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
