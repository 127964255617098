import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@src/utility/api';

// import api from "axios";
export const fetchAll = createAsyncThunk(
    'lookups/fetchAll',
    async (params) => {
        const response = await api.get("/api/lookups", { params })
        return response.data;
    }
);



const lookupsSlice = createSlice({
    name: 'lookups',
    initialState: {
        items: {
            airLineCode: [],
            bookingType: [],
            departments: [],
            periority: [],
            flightType: [],
            informationSource: []
        },
        loading: false,
        error: null,
    },
    reducers: {
        // Other reducer actions...
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                state.items = action.payload
            })
    },
});


export default lookupsSlice.reducer;

