import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api';

export const refundRequestApi = createApi({
    reducerPath: 'refundRequestApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        createRefundRequest: build.mutation({
            query: (values) => ({
                url: '/api/request-refund/csu',
                method: 'POST',
                data: values,
                showToast: true,
            }),
        }),

        fetchAllRefundRequests: build.query({
            query: (params) => ({
                url: '/api/refund/search',
                method: 'POST',
                data: params,
            }),
        }),

        fetchRefundRequestById: build.query({
            query: (id) => ({
                url: `/api/refund-request/details/${id}`,
                method: 'GET',
            }),
        }),

        fetchBookingDetailsByPnr: build.query({
            query: (id) => ({
                url: `/api/refund-request/booking-details/${id}`,
                method: 'GET',
            }),
        }),

        fetchBookingPaymentsByPnr: build.query({
            query: (id) => ({
                url: `/api/refund-request/booking-payments/${id}`,
                method: 'GET',
            }),
        }),

        fetchPassengerFlightsByPnr: build.query({
            query: (id) => ({
                url: `/api/refund-request/passenger-flights/${id}`,
                method: 'GET',
            }),
        }),

        fetchBookingAmountsByPnr: build.query({
            query: (id) => ({
                url: `/api/refund-request/booking-amounts/${id}`,
                method: 'GET',
            }),
        }),

        closeRequests: build.mutation({
            query: (payload) => ({
                url: '/api/request-refund/close-requests',
                method: 'POST',
                data: payload,
                showToast: true,
            }),
        }),

        assignRequest: build.mutation({
            query: (payload) => ({
                url: '/api/refund-request/assign',
                method: 'POST',
                data: payload,
                showToast: true,
            }),
        }),

        addHistory: build.mutation({
            query: (payload) => ({
                url: '/api/refundrequest/addhistory',
                method: 'POST',
                data: payload,
                showToast: true,
            }),
        }),

        exportToExcel: build.mutation({
            query: (payload) => ({
                url: '/api/refund-request/export-to-excel',
                method: 'POST',
                data: payload,
                showToast: true,
                successMessage: "Request Executed Successfully, you will receive an email with the excel file",
            }),
        }),

        completeRequest: build.mutation({
            query: (payload) => ({
                url: '/api/refund-request/complete',
                method: 'POST',
                data: payload,
                showToast: true,
            }),
        }),

        getDashBoardSummaries: build.query({
            query: (payload) => ({
                url: '/api/refund-request/dashboard-summaries',
                method: 'POST',
                data: payload
            }),
        }),
    }),
});

export const {
    useCreateRefundRequestMutation,
    useFetchAllRefundRequestsQuery,
    useFetchRefundRequestByIdQuery,
    useFetchBookingDetailsByPnrQuery,
    useFetchBookingPaymentsByPnrQuery,
    useFetchPassengerFlightsByPnrQuery,
    useFetchBookingAmountsByPnrQuery,
    useCloseRequestsMutation,
    useAssignRequestMutation,
    useAddHistoryMutation,
    useExportToExcelMutation,
    useCompleteRequestMutation,
    useGetDashBoardSummariesQuery,
} = refundRequestApi;