import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@src/redux/api';

export const bankApi = createApi({
  reducerPath: 'bankApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    fetchAllBanks: build.query({
      query: (params) => ({
        url: '/api/bank/list',
        method: 'GET',
        params: params,
      }),
    }),
  }),
});

export const { useFetchAllBanksQuery } = bankApi;