import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "@src/utility/api";
import toast, { Toaster } from "react-hot-toast";

export const fetchAll = createAsyncThunk("setting/fetchAll", async () => {
  const response = await api.get("/api/setting/get");
  return response.data;
});

export const update = createAsyncThunk(
  "setting/update",
  async ({ values }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/api/setting/update`, values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

const settingSlice = createSlice({
  name: "setting",
  initialState: {
    settingOptions: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.loading = false;
        state.settingOptions = action.payload;
      })
      .addCase(update.fulfilled, (state, action) => {
        const { successCallBack } = action.meta.arg;
        state.loading = false;
        state.error = null;
        const { type } = action;
        if (successCallBack) {
          successCallBack(action.payload);
        }
      })
  },
});

export default settingSlice.reducer;
