import { api } from '@app/layout'
import { toast } from 'sonner'


export const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) =>
    async ({ url, method, data, params, headers, showToast = false, successMessage = "Request executed successfully", errorMessage = "There was an error" }) => {
        try {
            const promise = api({
                url: baseUrl + url,
                method,
                data,
                params,
                headers,
            })

            showToast && toast.promise(promise, {
                loading: 'Loading...',
                success: (data) => {
                    return successMessage;
                },
                error: (error) => {
                    return errorMessage;
                },
            });
            
            const result = await promise
            return { data: result.data }
        } catch (axiosError) {
            const err = axiosError
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }



