// ** Redux Imports
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { lookupApi } from "./lookupApi";
import { refundRequestApi } from './refundRequestsApi'
import { bankApi } from './bankApi'
import { reasonApi } from './reasonApi'

const middlewares = [
  lookupApi.middleware,
  refundRequestApi.middleware,
  bankApi.middleware,
  reasonApi.middleware
];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {

    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares);
  },
});
setupListeners(store.dispatch)
export { store };
