import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@src/utility/api';

// import api from "axios";
export const fetchAll = createAsyncThunk(
    'banks/fetchAll',
    async (params) => {
        const response = await api.get("/api/bank/list", { params })
        return response.data;
    }
);



const bankSlice = createSlice({
    name: 'banks',
    initialState: {
        items: [],
        loading: false,
        error: null,
    },
    reducers: {
        // Other reducer actions...
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                state.items = action.payload
            })
    },
});


export default bankSlice.reducer;

