// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'

import { lookupApi } from './lookupApi'
import { refundRequestApi } from './refundRequestsApi'
import { bankApi } from './bankApi'
import { reasonApi } from './reasonApi'
import lookups from './lookups'
import reasons from './reasons'
import banks from './banks'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import invoice from '@src/views/apps/invoice/store'


import refundRequests from './refundRequests'
import setting from './setting'
const rootReducer = {
  "lookupApi": lookupApi.reducer,
  "refundRequestApi": refundRequestApi.reducer,
  "bankApi": bankApi.reducer,
  "reasonApi": reasonApi.reducer,
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  lookups,
  refundRequests,
  setting,
  banks,
  reasons
}

export default rootReducer
