import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@src/redux/api';

export const reasonApi = createApi({
  reducerPath: 'reasonApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    fetchAllReasons: build.query({
      query: (params) => ({
        url: '/api/reason/list',
        method: 'GET',
        params: params,
      }),
    }),
  }),
});

export const { useFetchAllReasonsQuery } = reasonApi;